import { motion } from 'framer-motion'
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";


function Settings() {

  const navigate = useNavigate();

  const signUserOut = event => {
    console.log("Signing out...");
    const auth = getAuth();
    signOut(auth).then(() => {
      console.log("User signed out");
      navigate("/", { replace: true });
    }).catch((error) => {
      console.log(error);
    });
  }

  return(
    <motion.div 
      className="Settings"
      initial = {{opacity: 0}}
      animate = {{opacity: 1}}
      exit = {{opacity: 0, transition: {duration: 0.5}}}
    >

      <header className="header">

        <a href='/updateEmail'>
            <h2>Update Email</h2>
        </a>

        <a href='/resetPassword'>
            <h2>Reset Password</h2>
        </a>

        <a onClick={signUserOut}>
            <h2>Sign Out</h2>
        </a>

        <a href='/deleteAccount'>
            <h2>Delete Account</h2>
        </a>

      </header>

    </motion.div>
  );
  }
  
  export default Settings;