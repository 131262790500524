// Pages
import Welcome from "./pages/Welcome";
import Home from "./pages/Home";
import Philosophy from "./pages/Philosophy";
import Contact from "./pages/Contact";
import Join from "./pages/Join";
import User from "./pages/User";
import FinishSignUp from "./pages/finishSignUp";
import Login from "./pages/Login";
import TierMenu from "./pages/TierMenu";
import VerifyEmail from "./pages/VerifyEmail";
import ResetPassword from "./pages/ResetPassword";
import Settings from "./pages/Settings";
import UpdateEmail from "./pages/UpdateEmail";
import Reauthenticate from "./pages/Reauthenticate";
import DeleteAccount from "./pages/DeleteAccount";
import SetCultName from "./pages/SetCultName";
import Archives from "./pages/Archives";
import FloatingPoint from "./pages/Archives/FloatingPoint";

import { AnimatePresence } from "framer-motion";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import { getAuth } from "firebase/auth";


const { getFirestore } = require('firebase/firestore');
const firebase = require('firebase/app');
//
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCQOVL_KS8WtVJRbN7GloCIsulzln3AGhU",
  authDomain: "cult-8b48f.firebaseapp.com",
  projectId: "cult-8b48f",
  storageBucket: "cult-8b48f.appspot.com",
  messagingSenderId: "683967380384",
  appId: "1:683967380384:web:a198da8c13483b6dde42c5",
  measurementId: "G-CEGMLQCDQG"
};
// Initialize the Firebase app
export const app = firebase.initializeApp(firebaseConfig);
export const db = getFirestore(app);
//export const auth = getAuth();

function App() {
// initial={false} 

  return (
    <BrowserRouter>
      <AnimatePresence exitBeforeEnter>
        <Routes>
          <Route
            exact path='/'
            element={<Welcome/>}
          /> 
          <Route
            exact path='/home'
            element={<Home/>}
          />
          <Route
            exact path='/philosophy'
            element={<Philosophy/>}
          />
          <Route
            exact path='/contact'
            element={<Contact/>}
          />
          <Route
            exact path='/join'
            element={<Join/>}
          />
          <Route
            exact path='/finishSignUp'
            element={<FinishSignUp/>}
          />
          <Route
            exact path='/VerifyEmail'
            element={<VerifyEmail/>}
          />
          <Route
            exact path='/cultUser'
            element={<User/>}
          />
          <Route
            exact path='/login'
            element={<Login/>}
          />
          <Route
            exact path='/user'
            element={<User/>}
          />
          <Route
            exact path='/tier-menu'
            element={<TierMenu/>}
          />
          <Route
            exact path='/resetPassword'
            element={<ResetPassword/>}
          />
          <Route
            exact path='/settings'
            element={<Settings/>}
          />
          <Route
            exact path='/updateEmail'
            element={<UpdateEmail/>}
          />
          <Route
            exact path='/reauthenticate'
            element={<Reauthenticate/>}
          />
          <Route
            exact path='/deleteAccount'
            element={<DeleteAccount/>}
          />
          <Route
            exact path='/setCultName'
            element={<SetCultName/>}
          />
          <Route
            exact path='/archives'
            element={<Archives/>}
          />
          <Route
            exact path='/floatingPoint'
            element={<FloatingPoint/>}
          />

        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );

}

export default App;
