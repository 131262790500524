import {useState} from 'react';
import { motion } from 'framer-motion'
import { getAuth, sendEmailVerification, createUserWithEmailAndPassword } from "firebase/auth";
import { useHistory } from "react-router-dom";
import "./css/join.scss"
import { useNavigate } from "react-router-dom";




function Join() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const createNewUser = event => {
        // prevent page refresh
        event.preventDefault(); 

        const auth = getAuth();
        

        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log(user);
                sendEmailVerification(auth.currentUser).then(() => {
                    console.log("Email verification sent");
                    alert("Please confirm your email first before logging in");
                    // Redirect to login page
                    navigate("/login", { replace: true });

                });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(error);
                alert(errorMessage);
            });
        


    }

    return (
      <motion.div 
        className="Join"
        initial = {{opacity: 0}}
        animate = {{opacity: 1}}
        exit = {{opacity: 0, transition: {duration: 0.5}}}
      >

        <header className="Join-header">

            <h1>Join the CULT:</h1>

            <form onSubmit={createNewUser}>

                <div>
                <input placeholder="Email" required onChange={event => setEmail(event.target.value)}/>
                </div>
                
                <div>
                <input type="password" placeholder="Password" required onChange={event => setPassword(event.target.value)}/>
                </div>
                <div>
                <input type="password" placeholder="Confirm Password" required onChange={event => setConfirmPassword(event.target.value)}/>
                </div>
                
                <select name="choice" required>
                <option value="">Pick One</option>
                <option value="y">01001101 01100001 01101011 01100101 00100000 01100001 01100011 01100011 01101111 01110101 01101110 01110100</option>
                <option value="n">01000100 01101111 01101110 00100111 01110100 00100000 01101101 01100001 01101011 01100101 00100000 01100001 01100011 01100011 01101111 01110101 01101110 01110100</option>
                </select>

                <input type="submit" />

            </form>

        </header>

        

      </motion.div>
    );
  }
  
  export default Join;