import {useState} from 'react';
import { motion } from 'framer-motion'
import { getAuth, deleteUser } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "react-native";
function DeleteAccount() {

    const navigate = useNavigate();

    const deleteAccount = event => {
        // prevent page refresh
        event.preventDefault(); 
        const auth = getAuth();
        if (auth.currentUser === null) {
            console.log("Re-authenticating user");
            navigate("/reauthenticate");
        }
        deleteUser(auth.currentUser).then(() => {
            console.log("User Deleted");
            navigate("/", { replace: true });
          }).catch((error) => {
            console.log(error);
          });
    }

    const no = event => {
        navigate("/user", { replace: true });
    }
  

  return(
    <motion.div 
      className="DeleteAccount"
      initial = {{opacity: 0}}
      animate = {{opacity: 1}}
      exit = {{opacity: 0, transition: {duration: 0.5}}}
    >

      <header className="ResetPassword-header">

        <h1>Are you sure you want to leave the CULT?</h1>
        <form onSubmit={deleteAccount}>
            <input type="submit" value="YES"></input>
        </form>
        <form onSubmit={no}>
            <input type="submit" value="NO"></input>
        </form>

      </header>

    </motion.div>
  );
  }
  
  export default DeleteAccount;