import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'
import { getAuth, onAuthStateChanged } from "firebase/auth";


const Home = () => {

    const [user, setUser] = useState('');
    const auth = getAuth();

    // Use useEffect(), [] to only call once per render 
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
          if (user) {
            setUser(user);
          } 
      });
    }, []);

    if (user) {
      return(
        <motion.div 
        className="Home"
        initial = {{opacity: 0}}
        animate = {{opacity: 1}}
        exit = {{opacity: 0, transition: {duration: 0.5}}}
        >

          <header className="Home-header">

            <h1>WELCOME BACK TO  THE CULT {user.displayName}</h1>

            <h1 className='sidebar'>
              <a className='sidebar-title' href='/philosophy'>
                PHILOSOPHY
              </a>
            </h1>
            <h1 className='sidebar'>
              <a className='sidebar-title' href='/archives'>
                ARCHIVES
              </a>
            </h1>
            <h1 className='sidebar'>
              <a className='sidebar-title' href='/contact'>
                CONTACT
              </a>
            </h1>
            <h1 className='sidebar'>
              <a className='sidebar-title' href='/user'>
                USER PAGE
              </a>
            </h1>

          </header>

        </motion.div>
      );
    }

    else {
      return (
        <motion.div 
        className="Home"
        initial = {{opacity: 0}}
        animate = {{opacity: 1}}
        exit = {{opacity: 0, transition: {duration: 0.5}}}
        >

          <header className="Home-header">

            <h1 className='sidebar'>
              <a className='sidebar-title' href='/philosophy'>
                PHILOSOPHY
              </a>
            </h1>
            <h1 className='sidebar'>
              <a className='sidebar-title' href='/contact'>
                CONTACT
              </a>
            </h1>
            <h1 className='sidebar'>
              <a className='sidebar-title' href='/join'>
                JOIN
              </a>
            </h1>
            <h1>
              <a href='/login'>
                <span className='login'></span>
              </a>
            </h1>

          </header>

        </motion.div>
      );
    }
    

  }
  
  export default Home;