import { motion } from 'framer-motion'
import './css/App.css';


function Contact() {
  return(
    <motion.div 
      className="Contact"
      initial = {{opacity: 0}}
      animate = {{opacity: 1}}
      exit = {{opacity: 0, transition: {duration: 0.5}}}
    >

      <header className="Contact-header">

      <h1>
          <a href="/home">
              <span className='contact-title'></span>
          </a>
      </h1>

      </header>


      

    </motion.div>
  );
  }
  
  export default Contact;