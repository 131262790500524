import { motion } from 'framer-motion'
import './css/App.css';


function TierMenu() {
    return (
        <motion.div 
          className="TierMenu"
          initial = {{opacity: 0}}
          animate = {{opacity: 1}}
          exit = {{opacity: 0, transition: {duration: 0.5}}}
        >
    
          <header className="App-header">
    
            <h2>NOTE: Must complete Tier's in order</h2>
            <ul>
                <li>Tier 1: Make CULT account</li>
                <li>Tier 2: Become INITIATED</li>
                <li>Tier 3: Get CULT SYMBOL TATTED on you</li>
            </ul>
    
          </header>
    
        </motion.div>
      );
  }
  
  export default TierMenu;


