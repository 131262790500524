import { motion } from 'framer-motion'
import { useNavigate } from "react-router-dom";

function VerifyEmail() {

  const navigate = useNavigate();
  const login = event => {
    navigate("/login", { replace: true })
  }

  return(
    <motion.div 
      className="VerifyEmail"
      initial = {{opacity: 0}}
      animate = {{opacity: 1}}
      exit = {{opacity: 0, transition: {duration: 0.5}}}
    >

      <header className="header">

        <h1>Once Email is verified login:</h1>
        
        <form onSubmit={login}>
        <input type="submit" value="Login"/>
        </form>
     

      </header>


      

    </motion.div>
  );
  }
  
  export default VerifyEmail;