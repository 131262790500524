import { motion } from 'framer-motion'
import css from './css/Philosophy.module.css';


function Philosophy() {
    return (
      <motion.div 
        className="Philosophy"
        initial = {{opacity: 0}}
        animate = {{opacity: 1}}
        exit = {{opacity: 0, transition: {duration: 0.5}}}
      >

        <header className={css.header}>

        <h1>
          <center>
            <a className={css.aText} href="/home">
              The end is really the beginning
            </a>
            <h2>0 111 000</h2>
          </center>
        </h1>

        </header>


        

      </motion.div>
    );
  }
  
  export default Philosophy;