import {useState, useEffect} from 'react';
import { motion } from 'framer-motion'
import { getAuth, updateProfile, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function SetCultName() {

    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const navigate = useNavigate();

    // Use useEffect(), [] to only call once per render 
    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user.displayName === null) {
                setUsername(false);
            }
            else if (user.displayName !== null) {
                setUsername(user.displayName);
            }
        });
    }, []);

    const setCultName = event => {
        // prevent page refresh
        event.preventDefault(); 
        const auth = getAuth();

        updateProfile(auth.currentUser, {
            displayName: name
          }).then(() => {
            console.log("Display name updated")
            navigate("/user", { replace: true });
          }).catch((error) => {
            console.log(error);
          });
    }
  

  return(
    <motion.div 
      className="SetCultName"
      initial = {{opacity: 0}}
      animate = {{opacity: 1}}
      exit = {{opacity: 0, transition: {duration: 0.5}}}
    >

      <header className="SetCultName-header">

        <form onSubmit={setCultName}>
            <div>
                <input placeholder={username ? username : 'Set Cult Name'} required onChange={event => setName(event.target.value)}/>
            </div>
            <input type="submit" value="Update CULT Name"/>
        </form>

      </header>

    </motion.div>
  );
  }
  
  export default SetCultName;