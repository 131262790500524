import {useState} from 'react';
import { motion } from 'framer-motion'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "./css/join.scss"


function FinishSignUp() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const loginUser = event => {
        // prevent page refresh
        event.preventDefault(); 

        const auth = getAuth();

        signInWithEmailAndPassword(auth, email, password) 
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log(user);
                // TODO: Once user logs in -> call backend function to create actual user in database 
                //      ONLY IF VERIFIED. If not verified -> return false from backend and tell user to verify first
              }) 
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(error);
                alert(errorMessage);
              });
        

    }

    return (
      <motion.div 
        className="finishSignUp"
        initial = {{opacity: 0}}
        animate = {{opacity: 1}}
        exit = {{opacity: 0, transition: {duration: 0.5}}}
      >

        <header className="Join-header">

            <h1>Sign in to CULT after verifying Email:</h1>

            <form onSubmit={loginUser}>

                <div>
                <input placeholder="Email" required onChange={event => setEmail(event.target.value)}/>
                </div>
                
                <div>
                <input placeholder="Password" required onChange={event => setPassword(event.target.value)}/>
                </div>

                <input type="submit" />

            </form>

        </header>

        

      </motion.div>
    );
  }
  
  export default FinishSignUp;