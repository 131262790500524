import { motion } from 'framer-motion';
import './css/App.css';
import css from './css/User.module.css';
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from '../App.js';
import { Button, SafeAreaView, StyleSheet, Modal, 
    View, TextInput, Dimensions } from "react-native";
import { AiFillSetting } from "react-icons/ai";


const User = () => {

    const [user, setUser] = useState('');
    const [docData, setDocData] = useState('');
    const [username, setUsername] = useState('');
    const [initiated, setInitiation] = useState('');
    const auth = getAuth();
    const navigate = useNavigate();

    const [isModalVisible, setModalVisible] = useState(false);
    // This is to manage TextInput State
    const [inputValue, setInputValue] = useState("");
  
    // Create toggleModalVisibility function that will
    // Open and close modal upon button clicks.
    const toggleModalVisibility = () => {
        setModalVisible(!isModalVisible);
    };

    // Use useEffect(), [] to only call once per render 
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                console.log(user);
    
                const docRef = doc(db, "users", user.uid);
                getDoc(docRef).then(docSnap => {
                    if (docSnap.exists()) {
                        setDocData(docSnap.data());
                        setInitiation(docData.initiated);
                    } 
                });
    
                if (user.displayName === null) {
                    setUsername('not set');
                }
                else if (user.displayName !== null) {
                    setUsername(user.displayName);
                }
    
    
            } 
            else {
                // User is signed out
                // Redirect to Home page
                navigate("/home", { replace: true });
            }
        });
    }, []);
    
    const styles = StyleSheet.create({
        screen: {
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
        },
        viewWrapper: {
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
        modalView: {
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            elevation: 5,
            transform: [{ translateX: -(100 * 0.4) }, 
                        { translateY: -90 }],
            height: 180,
            width: 100 * 0.8,
            backgroundColor: "#fff",
            borderRadius: 7,
        },
        textInput: {
            width: "80%",
            borderRadius: 5,
            paddingVertical: 8,
            paddingHorizontal: 16,
            borderColor: "rgba(0, 0, 0, 0.2)",
            borderWidth: 1,
            marginBottom: 8,
        },
    });

    return (
      <motion.div 
        className="User"
        initial = {{opacity: 0}}
        animate = {{opacity: 1}}
        exit = {{opacity: 0, transition: {duration: 0.5}}}
      >

        <header className="User-header">

            <center>
                
                <div className={css.menu}>
                    <a href="/home">
                        <Button title="Home" color={"black"}></Button>
                    </a>
                    <a href="/settings">
                        <AiFillSetting></AiFillSetting>
                    </a>
                </div>


                <img src={require("./cult_logo.jpg")} alt="CULT"></img>

                <h3>Email: {user ? user.email : 'Loading...'}</h3>
                <a href='/setCultName'>Cult Name: {user ? username : 'Loading...'}</a>

                <Modal animationType="slide" 
                    transparent visible={isModalVisible} 
                    presentationStyle="overFullScreen" 
                    onDismiss={toggleModalVisibility}>
                    <View style={styles.viewWrapper}>
                        <View style={styles.modalView}>
                            <TextInput placeholder="Enter something..." 
                                    value={inputValue} style={styles.textInput} 
                                    onChangeText={(value) => setInputValue(value)} />
    
                            {/** This button is responsible to close the modal */}
                            <Button title="Close" onPress={toggleModalVisibility} />
                        </View>
                    </View>
                </Modal>

                {initiated ? 
                    <h3>INITIATED</h3> : 
                    <h3>Not an Initiated Member <br></br><a href='/initiate'>Become INITIATED</a></h3> 
                }
                <h3>Tier: {docData ? docData.tier : 'Loading...'}</h3>
                <h3><a href="/tier-menu">Tier Menu</a></h3>
            </center>
        </header>

        

      </motion.div>
    );
  }
  
  export default User;