import logo from './cult_logo.jpg';
import './css/App.css';

function Welcome() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" /> 

        <div class="hero-container">
          <div class="environment"></div>

          <h2 class="hero glitch layers" data-text="CULT" data-hover="ENTER">
            <a href="/home">
              <span class="cult-title"></span>
            </a>
          </h2>

        </div>

      </header>
    </div>
  );
}

export default Welcome;