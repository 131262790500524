import {useState} from 'react';
import { motion } from 'framer-motion'

function FloatingPoint() {

  return(
    <motion.div 
      className="FloatingPoint"
      initial = {{opacity: 0}}
      animate = {{opacity: 1}}
      exit = {{opacity: 0, transition: {duration: 0.5}}}
    >

        <header className="FloatingPoint-header">

            
            <a href="https://azrael.digipen.edu/~mmead/www/Courses/CS220/IEEE754.html" target="_blank"> 
                <h1>0</h1> 
            </a>
            <a href="http://cstl-csm.semo.edu/xzhang/Class%20Folder/CS280/Workbook_HTML/FLOATING_tut.htm#:~:text=The%20sign%20of%20a%20binary,bit%20indicates%20a%20positive%20number.&text=Before%20a%20floating%2Dpoint%20binary,its%20mantissa%20must%20be%20normalized." target="_blank"> 
                <h1>1</h1> 
            </a>
            
        </header>

    </motion.div>
  );
  }
  
  export default FloatingPoint;