import {useState} from 'react';
import { motion } from 'framer-motion'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function ResetPassword() {

    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const resetPassword = event => {
        // prevent page refresh
        event.preventDefault(); 
        const auth = getAuth();
        /*if (auth.currentUser === null) {
            console.log("Re-authenticating user");
            navigate("/reauthenticate");
        }*/
        sendPasswordResetEmail(auth, email)
          .then(() => {
            alert("Password Reset link sent, please check email");
            navigate("/login", { replace: true });
            console.log("Password Reset link sent");
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage);
          });
    }
  

  return(
    <motion.div 
      className="ResetPassword"
      initial = {{opacity: 0}}
      animate = {{opacity: 1}}
      exit = {{opacity: 0, transition: {duration: 0.5}}}
    >

      <header className="ResetPassword-header">

        <form onSubmit={resetPassword}>
            <div>
                <input placeholder="Email" required onChange={event => setEmail(event.target.value)}/>
            </div>
            <input type="submit" value="Send Password Reset Link"/>
        </form>

      </header>


      

    </motion.div>
  );
  }
  
  export default ResetPassword;