import {useState} from 'react';
import { motion } from 'framer-motion'
import { getAuth, signInWithEmailAndPassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import "./css/join.scss"
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from '../App.js';




function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const loginUser = event => {
        // prevent page refresh
        event.preventDefault(); 

        const auth = getAuth();

        signInWithEmailAndPassword(auth, email, password) 
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log(user);

                if (!user.emailVerified) {
                    alert("Please verify your email first");
                    return;
                }
                
                // Check if new user
                const docRef = doc(db, "users", user.uid);
                getDoc(docRef).then(docSnap => {

                    if (docSnap.exists()) {
                        // Reauthenticate the user
                        const credentials = EmailAuthProvider.credential(email, password);
                        reauthenticateWithCredential(auth.currentUser, credentials).then((result) => {
                          console.log(result);
                          console.log("Existing User:", docSnap.data());
                          // Redirect to User page
                          navigate("/user", { replace: true });
                        });
                    } 

                    else {
                        console.log("New User, adding user to database");
                        setDoc(docRef, {
                            'tier': 1,
                            'initiated': false
                        }).then(() => {
                            console.log("New user created");
                            // Then redirect to User page, once new user is created
                            navigate("/user", { replace: true });
                        });
                    }
                      
                });


                
              }) 
              .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(error);
                alert(errorMessage);
              });
    }

    const resetPassword = event => {
      navigate("/resetPassword", { replace: true });
    }

    return (
      <motion.div 
        className="Login"
        initial = {{opacity: 0}}
        animate = {{opacity: 1}}
        exit = {{opacity: 0, transition: {duration: 0.5}}}
      >

        <header className="Join-header">

            <h1>Sign in to CULT:</h1>

            <form onSubmit={loginUser}>
                <div>
                <input placeholder="Email" required onChange={event => setEmail(event.target.value)}/>
                </div>
                
                <div>
                <input type="password" placeholder="Password" required onChange={event => setPassword(event.target.value)}/>
                </div>

                <input type="submit" />
            </form>

            <form onSubmit={resetPassword}>
              <input type="submit" value="Reset Password"/>
            </form>

        </header>

        

      </motion.div>
    );
  }
  
  export default Login;