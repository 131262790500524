import {useState, useEffect } from 'react';
import { motion } from 'framer-motion'
import { getAuth, updateEmail, sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function UpdateEmail() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const updateUserEmail = event => {
        // prevent page refresh
        event.preventDefault(); 
        const auth = getAuth();
        if (auth.currentUser === null) {
            console.log("Re-authenticating user");
            navigate("/reauthenticate");
        }
        console.log(auth);
        updateEmail(auth.currentUser, email).then(() => {

            alert("Email Updated, please verify your new email with the link sent to it");

            sendEmailVerification(auth.currentUser)
            .then(() => {
                console.log("Email update link sent");
                navigate("/login", { replace: true });
            });
            
          }).catch((error) => {
            console.log(error);
          });
    }
  

  return(
    <motion.div 
      className="UpdateEmail"
      initial = {{opacity: 0}}
      animate = {{opacity: 1}}
      exit = {{opacity: 0, transition: {duration: 0.5}}}
    >

      <header className="UpdateEmail-header">

        <form onSubmit={updateUserEmail}>
            <div>
                <input placeholder="New Email" required onChange={event => setEmail(event.target.value)}/>
            </div>
            <input type="submit" value="Send Email Update Link"/>
        </form>

      </header>

    </motion.div>
  );
  }
  
  export default UpdateEmail;