import {useState} from 'react';
import { motion } from 'framer-motion'
import { getAuth, sendPasswordResetEmail, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function Reauthenticate() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const reauthenticate = event => {
        // Reauthenticate the user
        const credentials = EmailAuthProvider.credential(email, password);
        const auth = getAuth();
        reauthenticateWithCredential(auth.currentUser, credentials).then((result) => {
            console.log(result);
            navigate(-1)
        });
    }
  

  return(
    <motion.div 
      className="Reauthenticate"
      initial = {{opacity: 0}}
      animate = {{opacity: 1}}
      exit = {{opacity: 0, transition: {duration: 0.5}}}
    >

      <header className="reauthenticate-header">

        <h1>You must re-login to perform this action:</h1>
        <form onSubmit={reauthenticate}>
            <div>
                <input placeholder="Email" required onChange={event => setEmail(event.target.value)}/>
            </div>
            <div>
                <input placeholder="Password" required onChange={event => setPassword(event.target.value)}/>
            </div>
            <input type="submit" value="Login"/>
        </form>

      </header>


      

    </motion.div>
  );
  }
  
  export default Reauthenticate;