import {useState} from 'react';
import { motion } from 'framer-motion'

function Archives() {

  return(
    <motion.div 
      className="Archives"
      initial = {{opacity: 0}}
      animate = {{opacity: 1}}
      exit = {{opacity: 0, transition: {duration: 0.5}}}
    >

        <header className="Archives-header">

            <a href="/floatingPoint">
                <h1>Floating Point Binary Representation</h1>    
            </a>
        </header>

    </motion.div>
  );
  }
  
  export default Archives;